import telephone from "../assets/telephone.png";
import phone from "../assets/phone.png";
import location from "../assets/location.png";
import contact from "../assets/contact.png";
import facebook from "../assets/facebook-icon.png";
import user from "../assets/user-icon.png";
import axios from "axios";
import { useEffect, useState } from "react";
function Footer() {
  const [count, setCount] = useState({ Count: 0 });
  useEffect(() => {
    async function getViewers() {
      try {
        const response = await axios.get("https://api.bibkom.pl/api/count");
        setCount(response.data.data[0]);
      } catch (err) {
        console.log(err);
      }
    }
    getViewers();
  }, []);
  return (
    <footer className="flexblock">
      <div className="flexblock pageWidth">
        <ul>
          <li className="flexblock">
            <img className="footer__location" src={location} />
            Kościuszki&nbsp;1D Rabka&nbsp;-&nbsp;Zdrój 34&#8288;-&#8288;700
          </li>
          <li className="flexblock">
            <a href="tel:736061061">
              <img src={phone} className="footer__phone" />
              736061061
            </a>
          </li>
          <li className="flexblock">
            <a href="tel:736061062">
              <img src={telephone} className="footer__telephone" />
              736061062
            </a>
          </li>
          <li className="flexblock">
            <a href="mailto: bibkom@bibkom.pl">
              <img className="footer__telephone" src={contact} />
              bibkom@bibkom.pl
            </a>
          </li>
          <li className="flexblock">
            <a href="https://www.facebook.com/BiBkom.sklep.serwis.PC.GSM/">
              <img className="footer__telephone" src={facebook} />
              Facebook
            </a>
          </li>
          <li>
            <img className="footer__telephone" src={user} />
            Odwiedzający: {count.Count}
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
