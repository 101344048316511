import { Link } from "react-router-dom";
import axios from "axios";
function Post(props) {
  const { Post_id, title, date, image } = props.data;
  const date2 = new Date(date);
  const formattedDate = date2.toLocaleDateString("en-GB");
  const setClick = props.setClick;
  const isLogged = props.isLogged;
  const handleSubmit = async (e) => {
    e.preventDefault();
    await deletePost(Post_id);
    setClick((prevClick) => prevClick + 1);
  };
  async function deletePost(id) {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `https://api.bibkom.pl/api/posts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err.response.data.msg);
    }
  }
  return (
    <div className="post">
      <form onSubmit={handleSubmit}>
        <div className="flexblock flex-responsive">
          <div className="header">
            <div className="title">{title}</div>
            <div className="date">{formattedDate}</div>
          </div>
          <img
            className="post-image"
            src={`${process.env.PUBLIC_URL}/${image}`}
          ></img>
        </div>
        <div className="post-button">
          <Link className="send-input" to={`/aktualności/${Post_id}`}>
            Czytaj
          </Link>
        </div>
        {isLogged && (
          <div className="post-button">
            <button className="send-input">Usuń</button>
          </div>
        )}
      </form>
    </div>
  );
}

export default Post;
